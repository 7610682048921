
<template>
  <section class="profile">
    <!-- <div class="page-header">
      <h3 class="page-title">
        Profile
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Sample Pages</a></li>
          <li class="breadcrumb-item active" aria-current="page">Profile</li>
        </ol>
      </nav>
    </div> -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-md-1">
                <!-- <div class="border-bottom text-center pb-4"> -->
                <img v-if="user_pic"
                  :src="(user_pic != '/images/avatar.jpg') ? user_pic : baseUrl + '/Believe-Backend/images/avatar.jpg'"
                  alt="profile" class="img-lg rounded-circle mb-3" />

                <!-- <img src="../../../assets/images/faces/face12.jpg" alt="profile" class="img-lg rounded-circle mb-3"/> -->
                <!-- <p>Bureau Oberhaeuser is a design bureau focused on Information- and Interface Design. </p>
                      <div class="d-block d-sm-flex justify-content-between">
                        <b-button variant="gradient-success">Hire Me</b-button>
                        <b-button variant="gradient-success">Follow</b-button>
                      </div> -->
                <!-- </div> -->
              </div>
              <div class="col-md-4 pt-2">
                <h3 class="mt-3 mt-lg-0">{{ userInfo?.name ?? 'unknown'}}</h3>
                <div class="d-flex align-items-center">
                  <h5 class="mb-0 mr-2 text-muted mt-3 mt-lg-0">{{ userInfo?.email ?? 'unknown'}}</h5>
                  <!-- <star-rating class="ml-3" v-bind:increment="0.5" v-bind:rating="3.5" v-bind:max-rating="5" v-bind:star-size="20" v-bind:show-rating="false" v-bind:read-only="true" :active-color="'#b66dff'"></star-rating> -->
                </div>
              </div>
              <div class="col-md-2 offset-md-5 text-right pt-4">
                <button type="button" class="btn btn-info believe-btn" @click="goBack()">
                  <i class="mdi mdi-keyboard-backspace"></i> Back</button>
              </div>
            </div>

            <div class="row">

              <div class="col-lg-8">



                <div class="mt-5 py-2">
                  <b-tabs class="tab tab-solid-primary">
                    <b-tab active>
                      <template slot="title">
                        <i class='mdi mdi-account-outline'></i> Information
                      </template>

                      <div class="pl-4">
                        <table class="table table-bordered">
                          <tr>
                            <td><b>Name</b></td>
                            <td class="text-muted">{{ userInfo.name ?? 'unknown'}}</td>
                          </tr>
                          <tr>
                            <td><b>Email</b></td>
                            <td class="text-muted">{{ userInfo?.email ?? 'unknown'}}</td>
                          </tr>
                          <tr>
                            <td><b>Type</b></td>
                            <td class="text-muted">{{ userInfo?.type ?? 'unknown'}}</td>
                          </tr>
                          <tr>
                            <td><b>Account Status</b></td>
                            <td class="text-muted">{{ userInfo?.status ?? 'unknown'}}</td>
                          </tr>
                          <tr>
                            <td><b>User Since</b></td>
                            <!-- <td class="text-muted">{{ userInfo?.created_at | moment('llll') ?? 'unknown'}}</td> -->
                            <td class="text-muted">{{ getFormattedTime(userInfo?.created_at) ?? 'unknown'}}</td>
                          </tr>
                          <tr>
                            <td><b>Last Used</b></td>
                            <td class="text-muted">
                              {{ getFormattedTime(userInfo?.login_date) ?? 'unknown'}}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Subscription Status</b></td>
                            <td class="text-muted">{{ subscriptions[0]?.subscription_status ?? 'unknown' }}</td>
                          </tr>
                          <tr>
                            <td><b>Signup Platform</b></td>
                            <td class="text-muted">{{ userInfo?.signup_platform ?? 'unknown'}}</td>
                          </tr>
                          <tr>
                            <td><b>Subscription Platform</b></td>
                            <td class="text-muted">{{ subscriptions[0]?.platform ?? 'unknown' }}</td>
                          </tr>
                          <tr>
                            <td><b>Subscription Package</b></td>
                            <td class="text-muted">{{ subscriptions[0]?.package ?? 'unknown' }}</td>
                          </tr>
                          <tr>
                            <td><b>Subscription Date</b></td>
                            <td class="text-muted">{{ getFormattedTime(subscriptions[0]?.created_at) ?? 'unknown'}}</td>
                          </tr>
                          <tr>
                            <td><b>Lifetime</b></td>
                            <td class="text-muted">{{ (userInfo?.life_time) ? 'Yes' : 'No' }}</td>
                          </tr>
                          <tr>
                            <td><b>Send Reset Link</b></td>
                            <td class="text-muted">
                              <button class="btn btn-outline-success"
                                @click="sendResetLinkToUser(user_email)">Send
                                <i class="mdi mdi-send" style="font-size:12px"></i></button>
                            </td>
                          </tr>
                          <tr v-if="subscriptions.length > 0 && subscriptions[0]?.status != 'expired' && subscriptions[0]?.subscription_status != 'Unsubscribed' && subscriptions[0]?.store != 'PLAY_STORE' && subscriptions[0]?.store != 'APP_STORE'">
                              <td><b>Cancel Subscription</b></td>
                              <td class="text-muted">
                                    <button
                                      class="btn btn-outline-danger" @click="showAlert(user_id, subscriptions[0]?.subscription_history_id)">Cancel</button>
                                </td>
                          </tr>
                          <tr v-if="subscriptions.length > 0">
                            <td><b>Go to Revenuecat's Details Page</b></td>
                            <td>
                              <!-- <li class="list-group-item text-right"> -->
                                <span class="">
                                      <!-- <router-link class="btn btn-primary" :to="{ name: 'OrderDetails',params: { id: data.id }}">View Details</router-link> -->
                                      <a class="btn btn-outline-primary" target="blank"
                                        :href="`https://app.revenuecat.com/customers/e0f8228e/${subscriptions[0]?.original_app_user_id}`">View
                                        Details</a>
                                    </span>
                                  <!-- </li> -->
                            </td>
                          </tr>
                        </table>
                        <!-- <p><b>Name : </b> <span class="text-muted px-2">{{ user_name }}</span></p> -->
                      </div>


                      <!-- <div class="row">
                      <div class="col-md-4">
                        <img class="img-fluid w-100 rounded" src="@/assets/images/samples/tab_preview/03.png"
                          alt="Sample Image">
                      </div>
                      <div class="col-md-8">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem vel eveniet qui, fuga
                          saepe inventore aspernatur, voluptate libero soluta quidem ,fuga saepe inventore aspernatur, ad
                          accusantium cumque voluptas, perferendis
                          natus laudantium.</p>
                      </div>
                    </div>
                    <p>Impedit dolores ea facere culpa numquam alias, reprehenderit iste corporis hic fugit sunt cum
                      magni.</p> -->
                    </b-tab>
                    <b-tab>
                      <template slot="title">
                        <i class='mdi mdi-home-outline'></i> Demographics
                      </template>

                      <div class="pl-4">
                        <table class="table table-bordered">
                          <tr>
                            <td><b>Gender</b></td>
                            <td class="text-muted">{{ userInfo?.gender ?? 'unknown'}}</td>
                          </tr>
                          <tr>
                            <td><b>Age</b></td>
                            <td class="text-muted">
                                {{ currentAge ? currentAge : userInfo?.age ?? 'unknown' }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Birthday</b></td>
                            <td class="text-muted">
                              <span v-if="userInfo?.age == null">
                                unknown
                              </span>
                              <span v-else>
                                {{ userInfo?.age?.includes('-') || userInfo?.age?.includes('Under') ? 'unknown' : userInfo?.age }}
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template slot="title">
                        <i class='mdi mdi-home-outline'></i> Engagement
                      </template>

                      <div class="pl-4">
                        <table class="table table-bordered">
                          <tr>
                            <td><b>Sessions Completed</b></td>
                            <td class="text-muted">{{ userInfo.total_sessions ?? 0 }}</td>
                          </tr>
                          <tr>
                            <td><b>Total Minutes Meditated</b></td>
                            <td class="text-muted">{{ Math.floor(userInfo?.meditation_duration / 60) ?? 0 }}</td>
                          </tr>
                          <!-- <tr>
                            <td><b>Consecutive Days of Meditation</b></td>
                            <td class="text-muted">{{ userInfo?.consicutive_days_meditation }}</td>
                          </tr> -->
                          <tr>
                            <td><b>Badges Earned</b></td>
                            <td class="text-muted">{{ userInfo?.total_earned_badges ?? 0}}</td>
                          </tr>
                        </table>
                      </div>

                    </b-tab>

                    <!-- <b-tab>
                      <template slot="title">
                        <i class='mdi mdi-home-outline'></i> Preferences
                      </template>
                      <div class="row">
                        <div class="col-md-4">
                          <img class="img-fluid w-100 rounded" src="@/assets/images/samples/tab_preview/03.png"
                            alt="Sample Image">
                        </div>
                        <div class="col-md-8">
                          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem vel eveniet qui,
                            fuga
                            saepe inventore aspernatur, voluptate libero soluta quidem ,fuga saepe inventore aspernatur,
                            ad
                            accusantium cumque voluptas, perferendis
                            natus laudantium.</p>
                        </div>
                      </div>
                      <p>Impedit dolores ea facere culpa numquam alias, reprehenderit iste corporis hic fugit sunt cum
                        magni.</p>
                    </b-tab> -->

                    <b-tab>
                      <template slot="title">
                        <i class='mdi mdi-home-outline'></i> Reminder Usage
                      </template>

                      <div class="pl-4">
                        <table v-if="userInfo?.reminders != null && userInfo?.reminders?.length > 0"
                          class="table table-bordered">
                          <thead>
                            <th>Title</th>
                            <th>Created</th>
                            <th>Reminder Date</th>
                            <th>Reminder Time</th>
                          </thead>
                          <tbody>
                            <tr v-for="reminder in userInfo?.reminders">
                              <td>{{ reminder['title'] }}</td>
                              <td>{{ getFormattedTime(reminder['created_at']) ?? 'unknown' }}</td>
                              <td>{{ getFormattedTime(reminder['time_too'],'ll') ?? 'unknown' }}</td>
                              <td>{{ getFormattedTime(reminder['time'],'h:mm A') ?? 'unknown' }}</td>
                            </tr>
                          </tbody>
                        </table>
                        <h4 v-else>No Reminder found</h4>
                      </div>

                    </b-tab>
                  </b-tabs>


                  <!-- <ul class="nav profile-navbar">
                    <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0);">
                        <i class="mdi mdi-account-outline"></i>
                        Info
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link active" href="javascript:void(0);">
                        <i class="mdi mdi-newspaper"></i>
                        Feed
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0);">
                        <i class="mdi mdi-calendar"></i>
                        Agenda
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0);">
                        <i class="mdi mdi-attachment"></i>
                        Resume
                      </a>
                    </li>
                  </ul> -->
                </div>


                <!-- <div class="profile-feed">
                  <div class="d-flex align-items-start profile-feed-item">
                    <img src="../../../assets/images/faces/face13.jpg" alt="profile" class="img-sm rounded-circle" />
                    <div class="ml-4">
                      <h6>
                        Mason Beck
                        <small class="ml-4 text-muted"><i class="mdi mdi-clock mr-1"></i>10 hours</small>
                      </h6>
                      <p>
                        There is no better advertisement campaign that is low cost and also successful at the same time.
                      </p>
                      <p class="small text-muted mt-2 mb-0">
                        <span>
                          <i class="mdi mdi-star mr-1"></i>4
                        </span>
                        <span class="ml-2">
                          <i class="mdi mdi-comment mr-1"></i>11
                        </span>
                        <span class="ml-2">
                          <i class="mdi mdi-reply"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-start profile-feed-item">
                    <img src="../../../assets/images/faces/face16.jpg" alt="profile" class="img-sm rounded-circle" />
                    <div class="ml-4">
                      <h6>
                        Willie Stanley
                        <small class="ml-4 text-muted"><i class="mdi mdi-clock mr-1"></i>10 hours</small>
                      </h6>
                      <img src="../../../assets/images/samples/1280x768/12.jpg" alt="sample" class="rounded mw-100" />
                      <p class="small text-muted mt-2 mb-0">
                        <span>
                          <i class="mdi mdi-star mr-1"></i>4
                        </span>
                        <span class="ml-2">
                          <i class="mdi mdi-comment mr-1"></i>11
                        </span>
                        <span class="ml-2">
                          <i class="mdi mdi-reply"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-start profile-feed-item">
                    <img src="../../../assets/images/faces/face19.jpg" alt="profile" class="img-sm rounded-circle" />
                    <div class="ml-4">
                      <h6>
                        Dylan Silva
                        <small class="ml-4 text-muted"><i class="mdi mdi-clock mr-1"></i>10 hours</small>
                      </h6>
                      <p>
                        When I first got into the online advertising business, I was looking for the magical combination
                        that would put my website into the top search engine rankings
                      </p>
                      <img src="../../../assets/images/samples/1280x768/5.jpg" alt="sample" class="rounded mw-100" />
                      <p class="small text-muted mt-2 mb-0">
                        <span>
                          <i class="mdi mdi-star mr-1"></i>4
                        </span>
                        <span class="ml-2">
                          <i class="mdi mdi-comment mr-1"></i>11
                        </span>
                        <span class="ml-2">
                          <i class="mdi mdi-reply"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="col-lg-4">

                <div class="py-4">
                  <h4 class="mb-4">Favorite Tracks</h4>
                  <div v-if="userInfo?.favorites && userInfo?.favorites?.length > 0" class="pr-4" style="max-height:280px; overflow-y: auto;">
                    <p class="clearfix" v-for="fav in userInfo?.favorites">
                      <span class="float-left">
                        <img :src="fav?.image" alt="Session Image" class="img-sm rounded-circle mr-2" />
                        {{ fav?.name }}
                      </span>
                      <span class="float-right text-muted">
                        <a href="javascript:;" @click="play_aud(fav?.path)"
                          class="btn btn-outline-success btn-rounded listbox w3-bar-item w3-button believe-btn">
                          <i class="mdi mdi-play"></i>
                        </a>
                      </span>
                    </p>
                  </div>
                  <p v-else>No Track Found!</p>
                </div>

                <div class="py-4">
                  <h4 class="mb-4">Tracks Added to Playlist</h4>
                  <div v-if="userInfo?.playlist_tracks && userInfo?.playlist_tracks?.length > 0" class="pr-4" style="max-height:280px; overflow-y: auto;">
                    <p class="clearfix"  v-for="fav in userInfo?.playlist_tracks">
                      <span class="float-left">
                        <img :src="fav?.image" alt="Session Image" class="img-sm rounded-circle mr-2" />
                        {{ fav?.name }}
                      </span>
                      <span class="float-right text-muted">
                        <a href="javascript:;" @click="play_aud(fav?.path)"
                          class="btn btn-outline-success btn-rounded listbox w3-bar-item w3-button believe-btn">
                          <i class="mdi mdi-play"></i>
                        </a>
                      </span>
                    </p>
                  </div>
                  <p v-else>No Track Found!</p>
                </div>


                <div class="border-bottom py-4">
                  <h4>User Preferences</h4>
                  <p class="mt-2">Current Goals :</p>
                  <div v-if="userInfo?.goals != null && userInfo?.goals.length > 0">
                    <label class="badge badge-outline-dark" v-for="goal in userInfo?.goals">
                      {{ goal.name }}
                    </label>
                  </div>
                  <p v-else>No Goal Found!</p>
                  <p class="mt-4">Current Feelings :</p>
                  <div v-if="userInfo?.feelings != null && userInfo?.feelings.length > 0">
                    <label class="badge badge-outline-dark" v-for="feel in userInfo?.feelings">
                      {{ feel.name }}
                    </label>
                  </div>
                  <p v-else>No Feel Found!</p>
                </div>
                <!-- <div class="border-bottom py-4">
                  <div class="d-flex mb-3">
                    <div class="progress progress-md flex-grow">
                      <div class="progress-bar bg-gradient-primary" role="progressbar" aria-valuenow="55" style="width: 55%" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="progress progress-md flex-grow">
                      <div class="progress-bar bg-gradient-success" role="progressbar" aria-valuenow="75" style="width: 75%" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div> -->



                <!-- <b-button variant="gradient-primary btn-block">Preview</b-button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
import Tag from "@/components/Tag.vue";
// import AudioFile from "@/components/AudioFile.vue";
import { VueEditor } from "vue2-editor";
// import { getAuth, sendPasswordResetEmail } from "firebase/auth";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
  name: 'ShowModal',
  mixins: [validationMixin],
  data() {
    return {
      pagetype: "",
      pageNumber: "", // search &
      trial_remain_days: "",
      userInfo: null,
      user_name: "",
      gender: "",
      user_age: "",
      user_email: "",
      total_sessions: "",
      baseUrl: "",
      user_status: "",
      life_time: "",
      signup_platform: "",
      user_since: "",
      last_login: "",
      user_pic: "",
      user_id: "",
      user_type: "",
      subscription_name: "",
      subscription_start: "",
      subscription_end: "",
      subscription_status: "",
      subscription_history: null,
      subscriptions: [],
      stripeYearlyProductId: "prod_OgDz8g3XUOygz2",
      stripeMonthlyProductId: "prod_OgE2yrolPFLqeX",
      // IosYearlyProductId: "31012023",
      // IosMonthlyProductId: "3101202313",
      // AndroidYearlyProductId: "29102022152",
      // AndroidMonthlyProductId: "25102022653",

      IosYearlyProductId: "114092023",
      IosMonthlyProductId: "014092023",
      AndroidYearlyProductId: "yearly_114092023",
      AndroidMonthlyProductId: "monthly_014092023",
    }
  },

  // watch:{
  // subscription_history(newVal){
  //   console.log('ac adfasdfa dfasdf asdf  :::::: ' , newVal);
  // }
  // },

  components: {
    VueEditor,
    Tag,
    // AudioFile
    // simpleSnotifysuccess
  },
  validations: {
    form: {
      name: {
        required,
      },
      // description: {
      //     required,
      // },
      // author: {
      //     required,
      // },
    }
  },
  watch: {
    tags(newVal) {
      if (newVal.length > 0) {
        this.tags_err = "";
      }
    }
  },

  created() {
    this.baseUrl = "https://api.believehypnosis.app";
    // window.location.origin
  },

  mounted() {
    window.addEventListener("keypress", function (e) {
      if (e.code == "Enter") {
        e.preventDefault()
      }
    }.bind(this));
    this.items();
  },

  computed: {
    currentAge() {
      // const currentDate = new Date();
      // const currentYear = currentDate.getFullYear();
      // const birthYear = currentYear - this.user_age;
      // const dateOfBirth = new Date(birthYear, currentDate.getMonth(), currentDate.getDate());
      // return dateOfBirth.toDateString();

      if (this.user_age == null || this.user_age?.includes('-') || this.user_age?.includes('Under')) {
        return false;
      }

      const dob = new Date(this.user_age);
      const currentDate = new Date();
      let age = currentDate.getFullYear() - dob.getFullYear();
      // Check if the birth month and day have already occurred in the current year
      if (
        currentDate.getMonth() < dob.getMonth() ||
        (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate())
      ) {
        age--; // Subtract 1 year if the birthday hasn't happened yet this year
      }
      return age;
    },
  },

  methods: {

    play_aud(path) {
      this.$swal({
        // icon: 'audio',
        title: "Audio",
        html: " <audio controls><source src=\"" + path + "\" type=\"audio/mpeg\" /></audio>",
        showCloseButton: true,
        showConfirmButton: false,
      });
    },

    showAlert(user_id, id) {
      try {
        let endpoint2 = `/admin/cancel-subscription-admin/`;
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.post(endpoint2, {
              'id': user_id,
              'sub_history_id': id
            });
            if (result.status == 200) {
              this.$swal(
                'Deleted!',
                'Subscription has been canceled.',
                'success'
              )
              this.items();
              // let prev_url = `/subscribed-users/list`;
              // this.$router.push({ path: prev_url });
            }
          }
        })
      } catch (error) {
        console.log(error)
      }
    },




    async items() {
      // id, name, author, description, script_id, text, image, tag
      let data = await this.$store.getters['getEditFormData'];
      if (data.length == 0) {
        data = localStorage.getItem('data_for_edit');
        data = JSON.parse(data);
      }
      this.pageNumber = data?.pageNumber;
      if (data?.type == "dashboard") {
        this.pagetype = data?.type
      }
      this.user_id = data?.id;
      this.getUserDetail(data?.id);
      this.webSubscriptionDetails(data?.id);
    },

    async getUserDetail(user_id) {
      try {
        let result = await api.get('/admin/user-details', {params: {user_id: user_id}});
        // console.log("Result", result);
        this.userInfo = result?.data['user_detail'];
        this.trial_remain_days = result?.data["user_detail"].trial_data?.trial_remain;
        this.user_name = result.data["user_detail"].name;
        this.user_age = result.data["user_detail"].age;
        this.gender = result.data["user_detail"].gender;
        this.user_email = result.data["user_detail"].email;
        this.total_sessions = result.data["user_detail"].total_sessions;
        this.user_pic = result.data["user_detail"].profile_image;
        this.user_status = result.data["user_detail"].status;
        this.life_time = result.data["user_detail"].life_time;
        this.signup_platform = result.data["user_detail"].signup_platform;
        this.user_since = result.data["user_detail"].created_at;
        this.last_login = result.data["user_detail"].login_date;
        let type = result.data["user_detail"].type;
        this.user_type = (result.data["user_detail"].type == "admin") ? 'Admin' : 'User';
        console.log('get user detail');
        console.log(result);
        if (result.data['user_detail'].life_time == "true") {
          this.subscription_name = "Life Time Subscription";
        } else {
          if (result.data["user_detail"].subscripted.length > 0) {
            let sub_name = result?.data["user_detail"]?.subscripted[0]?.name;
            sub_name = sub_name.split('_');
            if (sub_name.length > 0) {
              if (sub_name.length == 1) {
                this.subscription_name = sub_name[0];
              } else {
                this.subscription_name = sub_name[1];
              }
            }
            this.subscription_end = result?.data["user_detail"]?.subscripted[0]?.ends_at;
            this.subscription_start = result?.data["user_detail"]?.subscripted[0]?.starts_at;
            this.subscription_status = "Active"
          }
        }
        this.subscription_history = result?.data['user_detail']?.subscription_data;
        // this.$toast.success(result.data.message);
        // const list = document.getElementById("modals-container");
        //      list.removeChild(list.firstElementChild);
        //console.log("User Name", this.user_pic);
      } catch (error) {
        // this.$toast.error(error.response.data.message);
        console.log(error);
      }
    },



    // async sendResetLinkToUser(email) {
    //   const auth = getAuth();
    //   const firebase_response = await sendPasswordResetEmail(
    //     auth, email
    //   );
    //   alert('link sent');
    // },

    async sendResetLinkToUser(email) {
      try {
        await api.post(`/admin/forgot-password`, {
          email: email,
        });
        alert('link sent');
      } catch (error) {
        alert('something went wrong, please try again!')
      }
    },

    async webSubscriptionDetails(id) {
      let sub_history = await api.get(`admin/list-web-subscription-admin/${id}`);
      console.log('abc');
      // console.log(result.data);
      if (sub_history.status == 200) {
        // console.log('before loop : ' , result.data.subscriptions);
        let alter_data = [];
        let packageName = "Free";

        let array_1 = [];
        let array_2 = [];
        let array_3 = [];
        let array_4 = [];

        // const sub_history = await API.get("/list-web-subscription");
        this.revsub = sub_history.data.revcat_info.subscriber.subscriptions;
        this.entitlement = sub_history.data.revcat_info.subscriber.entitlements;
        console.log("rev: ", Object.values(this.revsub))
        console.log("ent: ", Object.values(this.entitlement))
        for (const [key, value] of Object.entries(this.revsub)) {
          console.log(key)

          array_1.push(value);
        }
        for (const [key, value] of Object.entries(this.entitlement)) {
          console.log(key)

          array_2.push(value);
        }
        const maxDate = new Date(Math.max(...array_2.map(e => new Date(e.purchase_date))));
        console.log("maxTime", maxDate);
        //from subscription object
        array_4 = array_1.filter(function (data) {
          if (new Date(Math.max(new Date(data.purchase_date))).getTime() == maxDate.getTime()) {
            return true;
          }
        });
        console.log("array 4 : ", array_4);
        //from entitlement object
        array_3 = array_2.filter(function (data) {
          if (new Date(Math.max(new Date(data.purchase_date))).getTime() == maxDate.getTime()) {
            return true;
          }
        });
        this.subPid = array_3[0].product_identifier;
        // console.log("array3: ", array_3);
        // console.log("active subscription: ", array_4)
        // console.log("all subscriptions: ", sub_history.data)
        if (this.subPid == this.stripeMonthlyProductId || this.subPid == this.IosMonthlyProductId || this.subPid == this.AndroidMonthlyProductId) {
          packageName = "Monthly";
        }
        if (this.subPid == this.stripeYearlyProductId || this.subPid == this.IosYearlyProductId || this.subPid == this.AndroidYearlyProductId) {
          packageName = "Yearly";
        }
        // this.stripe_subscription_id = sub_history.data.subscriptions?.subscriber?.subscriber_attributes?.subscription_id?.value;

        // if (array_4.length > 0) {
        const subscription_status =
          (array_4[0].unsubscribe_detected_at == null)
            ? "Subscribed" : "Unsubscribed";

        // }



        // this.subPid =  Object.entries(result.data.revcat_info.subscriber.subscriptions)[0]["0"];
        // if(this.subPid === this.stripeMonthlyProductId
        // || this.subPid === this.IosMonthlyProductId
        // || this.subPid === this.AndroidMonthlyProductId
        // ){
        //   packageName = "Monthly";
        // }
        // if(
        //   this.subPid === this.stripeYearlyProductId
        // || this.subPid === this.IosYearlyProductId
        // || this.subPid === this.AndroidYearlyProductId
        // ){
        //   packageName = "Yearly";
        // }
        // const entries = Object.values(result.data.revcat_info.subscriber.subscriptions);
        // console.log("check revecat ::: " , entries);
        // const subscription_status = 
        // (entries[0].unsubscribe_detected_at == null) 
        // ? "Subscribed" : "Unsubscribed";

        for (const [key, value] of Object.entries(sub_history.data.subscriptions)) {
          value.original_app_user_id = value.original_app_user_id.replace('$', '%24').replace(':', '%3A');
          value.package = packageName;
          value.subscription_status = subscription_status;
          alter_data.push(value);
        }

        console.log('after loop : ', alter_data);
        this.subscriptions = alter_data;
      }
    },


    // async webSubscriptionDetails(id) {
    //   let result = await api.get(`admin/list-web-subscription-admin/${id}`);
    //   console.log('abc');
    //   console.log(result.data);
    //   if (result.status == 200) {
    //     // console.log('before loop : ' , result.data.subscriptions);
    //     let alter_data = [];
    //     let packageName = "";
    //     this.subPid =  Object.entries(result.data.revcat_info.subscriber.subscriptions)[0]["0"];
    //     if(this.subPid === this.stripeMonthlyProductId
    //     || this.subPid === this.IosMonthlyProductId
    //     || this.subPid === this.AndroidMonthlyProductId
    //     ){
    //       packageName = "Monthly";
    //     }
    //     if(
    //       this.subPid === this.stripeYearlyProductId
    //     || this.subPid === this.IosYearlyProductId
    //     || this.subPid === this.AndroidYearlyProductId
    //     ){
    //       packageName = "Yearly";
    //     }
    //     const entries = Object.values(result.data.revcat_info.subscriber.subscriptions);
    //     console.log("check revecat ::: " , entries);
    //     const subscription_status = 
    //     (entries[0].unsubscribe_detected_at == null) 
    //     ? "Subscribed" : "Unsubscribed";

    //     for (const [key, value] of Object.entries(result.data.subscriptions)) {
    //       value.original_app_user_id = value.original_app_user_id.replace('$','%24').replace(':','%3A');
    //       value.package = packageName;
    //       value.subscription_status = subscription_status;
    //       alter_data.push(value);
    //     }



    //     console.log('after loop : ' , alter_data);
    //     this.subscriptions = alter_data;
    //   }
    // },


    goBack() {
      // let prev_url = '/subscribed-users/list';
      // if(this.$store.state.prev_url != "" && this.$store.state.prev_url != null){
      //   prev_url = this.$store.state.prev_url
      //   this.$store.state.prev_url = "";
      // }else if(this.subscriptions.length == 0){
      //   prev_url = '/free-users/list';
      // }
      // console.log(prev_url);

      let prev_url = "";
      if (this.pagetype == "dashboard") {
        prev_url = "/";
      } else {
        prev_url = this.$store.state.prev_url;
        if (prev_url == null || prev_url == "") {
          prev_url = localStorage.getItem('prev_url');
        }
      }
      this.$store.state.pageNumberAfterAction = this.pageNumber; // search &
      // console.log("prev url :::: " , prev_url);
      this.$router.push({ path: prev_url });
    },

    async onSubmit() {
      // console.log(this.tags.length);
      // console.log(this.$v.form.description.$error);
      // console.log(this.$v.form.author.$error);
      this.$v.form.$touch()

      if (this.$v.form.$anyError) {
        return false;
      }
      try {
        this.is_loading = true;
        let obj = this.$store.getters['getEditFormData'];
        // console.log('localstorage');
        //     console.log(localStorage.getItem('obj'));
        // if(!obj){
        //     obj = localStorage.getItem('obj');                      
        // }
        let endpoint = "/admin/update-tag";

        // if (obj != "") {
        //     if (obj.type == "hypnosis") {
        //         endpoint = "/admin/add-hypnosis";
        //     }
        //     else if (obj.type == "meditation") {
        //         endpoint = "/admin/add-meditation";
        //     }
        //     else if (obj.type == "affirmation") {
        //         endpoint = "/admin/add-affirmation";
        //     }
        // }

        //             formData.append("name", this.vid_name);
        // formData.append("script_id", this.cat_id);
        // formData.append("description", this.vid_desc);
        // formData.append("duration", this.text_duration);
        // formData.append("author", this.chapter_author);
        // formData.append("text", this.vid_text);
        // // formData.append("duration", this.file_duration);
        // formData.append("image", this.blob);

        // if(typeof this.tags == 'string'){
        //   formData.append('tag[]', this.tags);
        // }else{
        //   for (var i = 0; i < this.tags.length; i++) {
        //     formData.append('tag[]', this.tags[i]);
        //   }
        // }


        console.log(obj);
        const formData = new FormData();
        formData.append("id", this.form?.id);
        formData.append("tag", this.form?.name);


        let result = await api.post(endpoint, formData);
        const msg = await result.data.message;
        // console.log('after requrest');
        // console.log(result);
        this.$store.dispatch('setMessage', { msg });
        // console.log(this.$root.$refs);
        // this.showSnotifySuccess(msg)
        // console.log('cat id', obj.category_id);
        await this.$store.dispatch(`${obj.type}/all_after_submit`);
        let prev_url = `/tags/list`;
        this.$router.push({ path: prev_url });
        this.is_loading = false;
      } catch (error) {
        console.log(error);
        // this.progress = 0;
        // let err = error.response.data.message;
        // this.$store.dispatch('setMessage', { err });
        // this.showSnotifyError()
        // this.$toast.error(this.error);
      }

    },


    // showSnotifySuccess(msg) {
    //     let obj = this.$store.getters['getMessage'];
    //     this.$snotify.success(obj.msg, {
    //         timeout: 5000,
    //         showProgressBar: true,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //     });
    // },
    // showSnotifyError(msg) {
    //     let obj = this.$store.getters['getMessage'];
    //     this.$snotify.error(obj.msg, {
    //         timeout: 5000,
    //         showProgressBar: true,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //     });
    // },

  },




}
</script>